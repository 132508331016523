import Vue from 'vue'
import store from '../../../store'
import i18n from '@/modules/i18n'
import { setupAxios } from '@/modules/config/axios'

import BookingServiceDropdown from '@/components/layout/header/booking-service-dropdown.vue'

setupAxios()

const bookingService = new Vue({
  el: '#booking-service-dropdown',
  store,
  i18n,
  components: {
    BookingServiceDropdown
  }
})

window.vue = bookingService
