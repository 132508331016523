import get from 'lodash/get'

const dynamicLoadStore = (modules = {}) => ({
  beforeCreate () {
    const store = this.$store
    // register a new module only if doesn't exist

    if (!store) return

    Object.entries(modules).forEach(([name, storeModule]) => {
      if (!get(store, `state[${name}]`)) {
        store.registerModule(name, storeModule)
      }
    })
  }
})

export {
  dynamicLoadStore
}
