<template lang='pug'>
  .booking-services
    li.nav-item.booking-service.tablet
      .booking-service-links
        a.nav-link(@click.prevent.stop='toggleBookingServiceDropdownTablet')
          span.booking-service-icon.svg-icon.store-location-white
          span.booking-service-text(v-text='$t("retailStore.bookingServices")')

        .booking-service-dropdown(v-if='showBookingServiceDropdown')
          template(v-if='isLoading')
            .booking-service-loader
              .loader-spinner

          template(v-else)
            h4.sub-title(v-text='$t("retailStore.beautyServices")')
            ul
              li.booking-service-li(v-for='beautyServiceLink in beautyServiceLinks')
                a.booking-service-link(:href='beautyServiceLink.path')
                  p.booking-service-label(v-text='beautyServiceLink.label')

            hr

            h4.sub-title(v-text='$t("retailStore.storeEvent")')
            ul
              li.booking-service-li(v-for='storeEventLink in storeEventLinks')
                a.booking-service-link(:href='storeEventLink.path')
                  p.booking-service-label(v-text='storeEventLink.label')
                  p.booking-service-description(v-text='storeEventLink.description')

    li.nav-item.store-event.desktop
      .booking-service-links
        a.nav-link(@click.prevent.stop='toggleStoreEventDropdown')
          span.booking-service-icon.svg-icon.store-location-white
          span.booking-service-text(v-text='$t("retailStore.storeEvent")')
        .booking-service-dropdown(v-if='showStoreEventDropdown')
          template(v-if='isLoading')
            .booking-service-loader
              .loader-spinner

          template(v-else)
            ul
              li.booking-service-li(v-for='storeEventLink in storeEventLinks')
                a.booking-service-link(:href='storeEventLink.path')
                  p.booking-service-label(v-text='storeEventLink.label')
                  p.booking-service-description(v-text='storeEventLink.description')

    li.nav-item.beauty-service.desktop
      .booking-service-links
        a.nav-link(@click.prevent.stop='toggleBeautyServiceDropdown')
          span.booking-service-icon.svg-icon.calendar-white
          span.booking-service-text(v-text='$t("retailStore.beautyServices")')
        .booking-service-dropdown(v-if='showBeautyServiceDropdown')
          template(v-if='isLoading')
            .booking-service-loader
              .loader-spinner

          template(v-else)
            ul
              li.booking-service-li(v-for='beautyServiceLink in beautyServiceLinks')
                a.booking-service-link(:href='beautyServiceLink.path')
                  p.booking-service-label(v-text='beautyServiceLink.label')
</template>

<script>
import { bookingServiceLinks } from '@/mixins/bookingServiceLinksUtil.js'

export default {

  mixins: [
    bookingServiceLinks
  ],
  data () {
    return {
      showBookingServiceDropdown: false,
      showBeautyServiceDropdown: false,
      showStoreEventDropdown: false
    }
  },

  created () {
    window.addEventListener('click', () => {
      this.showBookingServiceDropdown = false
      this.showBeautyServiceDropdown = false
      this.showStoreEventDropdown = false
    })
  },

  destroyed () {
    window.removeEventListener('click', () => {
      this.showBookingServiceDropdown = false
      this.showBeautyServiceDropdown = false
      this.showStoreEventDropdown = false
    })
  },

  methods: {
    toggleBookingServiceDropdown () {
      this.fetchResources()
      this.showBookingServiceDropdown = !this.showBookingServiceDropdown
      this.showBeautyServiceDropdown = false
      this.showStoreEventDropdown = false
      this.$emit('closeDropdowns')
    },
    toggleBookingServiceDropdownTablet () {
      this.fetchResources()
      this.showBookingServiceDropdown = !this.showBookingServiceDropdown
      this.showBeautyServiceDropdown = false
      this.showStoreEventDropdown = false
      this.$emit('closeDropdowns')
    },
    toggleBeautyServiceDropdown () {
      this.fetchResources()
      this.showBeautyServiceDropdown = !this.showBeautyServiceDropdown
      this.showBookingServiceDropdown = false
      this.showStoreEventDropdown = false
      this.$emit('closeDropdowns')
    },
    toggleStoreEventDropdown () {
      this.fetchResources()
      this.showStoreEventDropdown = !this.showStoreEventDropdown
      this.showBookingServiceDropdown = false
      this.showBeautyServiceDropdown = false
      this.$emit('closeDropdowns')
    },
    close () {
      this.showBookingServiceDropdown = false
      this.showBeautyServiceDropdown = false
      this.showStoreEventDropdown = false
    }
  }
}
</script>

<style lang='scss' scoped>
  .booking-service, .beauty-service, .store-event {
    margin-right: 15px;
    padding-top: 0;
    cursor: pointer;

    @include font-weight-medium;
    text-transform: uppercase;
    font-size: 12px;
    display: inline-block;

    &:hover {
      .booking-service-icon, .booking-service-text {
        text-decoration: underline;
      }
    }

    &.tablet {
      display: inline-block;

      @include media-breakpoint-up(xl) {
        display: none;
      }

      hr {
        margin-left: 5px;
        margin-right: 5px;
        border: 1px solid $grey;
      }
    }

    &.desktop {
      display: none;

      @include media-breakpoint-up(xl) {
        display: inline-block;
      }
    }
  }

  .booking-service-loader {
    width: 100%;
    min-width: 150px;
    display: flex;
    justify-content: center;

    .loader-spinner {
      height: 30px;
      width: 30px;
      border-width: 4px;
    }
  }

  .booking-service-text {
    color: $white;
  }

  .booking-service-dropdown {
    user-select: none;
    cursor: default;
    position: absolute;
    top: 35px;
    padding: 10px;
    z-index: $zindex-fixed;
    line-height: 28px;
    color: $black !important;
    background: $white;
    border-top: none;
    box-shadow: 0 1px 1px 1px rgba(0,0,0,0.13);
  }

  .sub-title {
    @include font-weight-bold;
    text-transform: uppercase;
    line-height: 28px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .sub-title:not(:first-child) {
    margin-top: 10px;
  }

  .booking-service-icon {
    height: 24px;
    width: 24px;
    background-size: 24px 24px;
    vertical-align: bottom;
    margin-right: 5px;
  }

  .booking-service-link {
    display: block;
    letter-spacing: 0.3px;
    padding: 10px 5px;
    text-transform: capitalize;
    line-height: 16px;

    &:hover {
      p.booking-service-label {
        color: $brand-primary;
      }
    }
  }

  .booking-service-label {
    color: $black;
    font-size: 13px;
    font-weight: 300;
  }

  .booking-service-description {
    color: $grey-dark;
    font-size: 10px;
    font-weight: 300;
  }
</style>
