const timetrade = {
  staging: 'https://staging-sephora-cts.anzus.solutions/?action=schedule',
  production: 'https://sephora-cts.anzus.solutions/?action=schedule',
  showAllStores: false
}

const api = {
  development: '/api/booking-services',
  staging: '/api/booking-services',
  production: '/api/booking-services'
}

const serviceProviders = {
  benefit: 'benefit',
  events: `${I18n.siteCountryLs}events`,
  specialevents: `${I18n.siteCountryLs}specialevents`,
  specialeventlocationid: `specialevents${I18n.siteCountryLs}`,
  virtualappointmentslocationid: `virtualsephora${I18n.siteCountryLs}`,
  personalshopping: 'personalshopping',
  sephora: 'sephora',
  sephoraec: 'sephoraec',
  virtualappointments: 'virtualappointments'
}

export {
  timetrade,
  api,
  serviceProviders
}
