import bookingService from '@/store/modules/booking-service'
import { mapGetters, createNamespacedHelpers } from 'vuex'
import { dynamicLoadStore } from '@/mixins/store-util'
const { mapActions } = createNamespacedHelpers('bookingService')

const bookingServiceLinks = {
  mixins: [
    dynamicLoadStore({ bookingService })
  ],

  computed: {
    ...mapGetters('bookingService', ['pages', 'categories', 'isLoading', 'beautyClasses', 'pagesLite', 'beautyClassCount']),
    beautyServiceLinks () {
      const links = []

      links.push({ label: this.$t('retailStore.allBeautyServices'), path: '/beauty-services' })

      if (this.categories) {
        for (var i = 0; i < this.categories.length; i++) {
          const category = this.categories[i]
          if (
            !(
              (category.attributes['booking-vendor'] === 'In House' &&
               category.attributes['cutover-from'] === null &&
               category.attributes['cutover-till'] === null) ||
              category.attributes['activity-type'] !== 'Service'
            ) && category.attributes['status'] === 'published'
          ) {
            links.push({
              label: category.attributes['name'],
              path: `/beauty-services/${category.attributes['slug-url']}`
            })
          }
        }
      }

      return links
    },

    storeEventLinks () {
      const links = []

      links.push({
        label: this.$t('retailStore.discoverStoreEvents'),
        description: this.$t('retailStore.eventsDescription'),
        path: '/store-events'
      })
      links.push({
        label: this.$t('retailStore.findStore'),
        description: this.$t('retailStore.findStoreDescription'),
        path: '/store-locations'
      })
        links.push({
          label: this.$t('retailStore.classes'),
          description: this.$t('retailStore.beautyClassesDescription'),
          path: '/beauty-classes'
        })

      // if (this.pagesLite) {
      //   for (var i = 0; i < this.pagesLite.length; i++) {
      //     links.push({
      //       label: this.pagesLite[i]['attributes']['page-title'],
      //       description: this.pagesLite[i]['attributes']['dropdown-description'],
      //       path: `/stores-and-events/${this.pagesLite[i]['attributes']['slug-url']}`
      //     })
      //   }
      // }

      return links
    }
  },

  methods: {
    ...mapActions(['fetchCategories', 'fetchPages', 'fetchBeautyClasses', 'fetchEvents']),
    fetchResources () {
      if (this.categories.length === 0) {
        this.fetchCategories().then(() => {
          this.fetchPages().then(() => {
            this.fetchBeautyClasses().then(() => {
              this.fetchEvents().then(() => {
                this.$store.commit('bookingService/setLoading', false)
              })
            })
          })
        })
      }
    },
    fetchResourcesLitePagesOnly () {
      if (typeof this.pagesLite === 'undefined' || this.pagesLite.length === 0) {
 this.fetchPagesLite().then(() => {
          this.$store.commit('bookingService/setLoading', false)
      })
}
    },
    fetchResourcesLiteBeautyServicesOnly () {
      if (this.categories.length === 0) {
        this.fetchCategories().then(() => {
          this.$store.commit('bookingService/setLoading', false)
        })
      }
    },
    fetchResourcesLiteBeautyClassOnly () {
      this.fetchBeautyClasses().then(() => {
        this.$store.commit('bookingService/setLoading', false)
      })
    }
  }
}

export {
  bookingServiceLinks
}
