import axios from 'axios'
import get from 'lodash/get'
import { timetrade, api } from '@/modules/config/booking-services'
import { getCookie } from '@/modules/services/cookies'

const state = {
  categories: [],
  stores: [],
  globalStoreMessage: '',
  services: [],
  beautyClasses: [],
  events: [],
  schedules: [],
  banners: [],
  loading: true,
  pages: [],
  page: {},
  pagesLite: [],
  activities: [],
  beautyClassCount: 0,
  sortedActivities: {}
}

const sortByState = ['au']

const getAttr = (obj, keys) => {
  if (keys.length > 0) {
    return getAttr(obj[keys[0]], keys.slice(1))
  } else {
    return obj
  }
}

const compareAlphabetically = (keys) =>
  (a, b) => String(getAttr(a, keys)) < String(getAttr(b, keys)) ? -1 : 1

const camelCase = str => str.split(/[\W_]+/).map(w => w.replace(/^\w/, w[0].toUpperCase())).join('')
const formatStateKey = str => {
  const camcelCased = camelCase(str)
  return camcelCased[0].toLowerCase() + camcelCased.substring(1)
}

const getters = {
  categories (state) {
    return get(state, 'categories')
  },
  activities (state) {
    return get(state, 'activites')
  },
  services (state) {
    return get(state, 'services')
  },

  beautyClasses (state) {
    return get(state, 'beautyClasses')
  },

  events (state) {
    return get(state, 'events')
  },
  hasVacancy (state) {
    return (store, act) => {
      const found = getters.scheduleByActivity(state)(store, act)
      return found && found.attributes.available
    }
  },
  isLoading (state) {
    return state.loading
  },
  isBookable (state) {
    return (store, act) => {
      const found = getters.scheduleByActivity(state)(store, act)
      return found && found.attributes.bookable
    }
  },
  objectById (state) {
    return (type, idOrSlug) => state[type].find(obj => Number(obj.id) === Number(idOrSlug) || obj.attributes['slug-url'] === idOrSlug)
  },
  page (state) {
    return state.page
  },
  pages (state) {
    return get(state, 'pages')
  },
  pagesLite (state) {
    return get(state, 'pagesLite')
  },
  beautyClassCount (state) {
    return get(state, 'beautyClassCount')
  },
  scheduleByActivity (state) {
    return (store, act) => state.schedules.find(item =>
      Number(item.attributes['store-id']) === Number(store.id) &&
      Number(item.attributes['schedulable-id']) === Number(act.id))
  },
  stores (state) {
    return state.stores
  },
  storesByCity (state) {
    const cities = {}
    for (const s of state.stores) {
      if (cities[s.attributes.city] === undefined) {
        cities[s.attributes.city] = {
          city: s.attributes.city,
          slug: s.attributes['city-slug'],
          stores: []
        }
      }
      cities[s.attributes.city].stores.push(s)
    }
    const cityStores = Object.values(cities)
    cityStores.sort(compareAlphabetically(['city']))
    for (const obj of cityStores) {
      obj.stores.sort(compareAlphabetically(['attributes', 'name']))
    }
    return cityStores
  },
  sortedActivities (state) {
    return state.sortedActivities
  },
  isSortedByState (state) {
    if (typeof (state.stores) !== 'undefined' && state.stores.length > 0) {
      if (sortByState.includes(state.stores[0].attributes.country.iso_alpha2_code)) {
          return true
      }
    }
    return false
  },
  storesByState (state) {
    const states = {}

    for (const s of state.stores) {
      if (states[s.attributes.state] === undefined) {
        states[s.attributes.state] = {
          state: s.attributes.state,
          slug: s.attributes.state,
          stores: []
        }
      }
      states[s.attributes.state].stores.push(s)
    }
    const stateStores = Object.values(states)
    stateStores.sort(compareAlphabetically(['state']))
    for (const obj of stateStores) {
      obj.stores.sort(compareAlphabetically(['attributes', 'name']))
    }
    return stateStores
  },
  // Get object and return link with params from that object
  timetradeWorkflowUrl () {
    return (query) => {
      let url = ''
      const acid = getCookie('acid')
      const aaid = getCookie('aaid')
      const cheetah = getCookie('cheetah')

      if (vueSupport.env === 'production') {
        url = timetrade[vueSupport.env]
      } else {
        url = timetrade.staging
      }

      if (vueSupport.platform === 'web') {
        url += '&view=embed'
      }
      url += `&lang=${I18n.language.split('_')[1]}`

      if (cheetah) {
        const cardNumber = JSON.parse(decodeURIComponent(getCookie('cheetah'))).cn
        if (cardNumber) {
          url += `&questionId__cn=${cardNumber}`
        }
      }
      if (acid) {
        url += `&questionId__acid=${acid}`
      }
      if (aaid) {
        url += `&questionId__aaid=${aaid}`
      }
      if (!query) {
        return url
      }

      const pairs = Object.keys(query).map(key => {
        if (query[key]) {
          return `${key}=${encodeURIComponent(query[key])}`
        }
      })
      return `${url}&${pairs.join('&')}`
    }
  }
}

const mutations = {
  setBanners (state, banners) {
    state.banners = banners
  },
  setCategories (state, categories) {
    state.categories = categories
  },
  setEvents (state, events) {
    state.events = events
  },
  setBeautyClasses (state, beautyClasses) {
    state.beautyClasses = beautyClasses
  },
  setGlobalStoreMessage (state, message) {
    state.globalStoreMessage = message
  },
  setLoading (state, isLoading) {
    state.loading = isLoading
  },
  setPages (state, pages) {
    state.pages = pages
  },
  setPagesLite (state, pages) {
    state.pagesLite = pages
  },
  setBeautyClassCount (state, count) {
    state.beautyClassCount = count
  },
  setPage (state, page) {
    state.page = page
  },
  setActivities (state, activities) {
    state.activities = activities
  },
  setStores (state, stores) {
    state.stores = stores
  },
  setServices (state, services) {
    state.services = services
  },
  setSortedActivities (state, sortedActivities) {
    state.sortedActivities = sortedActivities
  },
  addOrReplaceCategories: addOrReplace.bind(null, 'categories'),
  addOrReplaceServices: addOrReplace.bind(null, 'services'),
  addOrReplaceStores: addOrReplace.bind(null, 'stores'),
  addOrReplaceBeautyClasses: addOrReplace.bind(null, 'beautyClasses'),
  addOrReplaceEvents: addOrReplace.bind(null, 'events'),
  addOrReplaceSchedules: addOrReplace.bind(null, 'schedules')

}

function addOrReplace (collectionName, state, items) {
  if (!items) {
    return
  }
  for (const item of items) {
    item.type = formatStateKey(item.type)
    if (item.type !== collectionName) {
      continue
    }
    const idx = state[collectionName].findIndex(t => t.id === item.id)
    if (idx >= 0) {
      state[collectionName] = [...state[collectionName].slice(0, idx), item, ...state[collectionName].slice(idx + 1)]
    } else {
      state[collectionName] = [...state[collectionName], item]
    }
  }
  if (collectionName === 'categories') {
    state[collectionName] // eslint-disable-line no-unused-expressions
  }
}

// releaseStage is only available at runtime, so we need to lazy load axios client
let _client = null
function getClient () {
  if (_client !== null) {
    return _client
  }

  let BASE_API = api.development
  const API_VER = 'v1'

  if (vueSupport.env === 'staging') {
    BASE_API = api.staging
  } else if (vueSupport.env === 'production') {
    BASE_API = api.production
  }

  _client = axios.create({
    baseURL: `${BASE_API}/api/${API_VER}`,
    headers: {
      'Accept': 'application/vnd.api+json',
      'Accept-Language': I18n.acceptLanguage
    },
    withCredentials: true
  })

  return _client
}

const actions = {
  async fetchBanners ({ commit }) {
    const { data } = await getClient().get('/banners')
    commit('setBanners', data.data)
    return data.data
  },
  async fetchCategory ({ commit }, activityGroupId) {
    const { data } = await getClient().get(`/activities?activity_group_id=${activityGroupId}`)
    commit('setServices', data.data)
    return data.data
  },
  async fetchPagesLite ({ commit }) {
    const { data } = await getClient().get('/luxola_dropdown')
    commit('setPagesLite', data.data)
    commit('setBeautyClassCount', data.meta.beauty_class_count)
  },
  async fetchCategories ({ commit }) {
    const { data } = await getClient().get('/activity_groups?include=services.activity_groups')
    // const { data } = await getClient().get('/activity_groups')
    commit('setCategories', data.data)
    commit('setActivities', data.included)
    commit('addOrReplaceServices', data.included)
    return data.data
  },

  async fetchEvents ({ commit, dispatch }, storeCode = null) {
    let data

    if (storeCode == null) {
        ({ data } = await getClient().get('/activities?activity_type=event'))
    } else {
        ({ data } = await getClient().get(`/activities?activity_type=event&store_code=${storeCode}`))
    }
    commit('setEvents', data.data)

    // Call fetchActivities and log its return value
    // const activities = await dispatch('fetchActivities')

    return data.data
  },
  async fetchBeautyClasses ({ commit }, storeCode = null) {
    let data
    if (storeCode == null) {
        ({ data } = await getClient().get('/activities?activity_type=beauty%20class'))
    } else {
        ({ data } = await getClient().get(`/activities?activity_type=beauty%20class&store_code=${storeCode}`))
    }

    commit('setBeautyClasses', data.data)
    return data.data
  },
  async fetchPages ({ commit }) {
    const { data } = await getClient().get('/pages')
    commit('setPages', data.data)
  },
  async fetchPage ({ commit }, slugUrl) {
    const { data } = await getClient().get(`/pages/${slugUrl}`)
    commit('setPage', data.data)
  },
  async fetchStore ({ commit }, slugUrl) {
    const { data } = await getClient().get(`/stores/${slugUrl}?include=activities.stores,schedules`)
    commit('addOrReplaceStores', [data.data])
    const eventArr = []
    const classesArr = []
    const servicesArr = []
    if (typeof data.included !== 'undefined') {
      for (const activity of data.included) {
        if (activity.type !== 'activities') {
          continue
        }
        if (activity['attributes']['activity-type'] === 'Event') {
          eventArr.push(activity)
        } else if (activity['attributes']['activity-type'] === 'Beauty Class') {
          classesArr.push(activity)
        } else if (activity['attributes']['activity-type'] === 'Service') {
          servicesArr.push(activity)
        }
      }
    }

    var sortedActivities = {
      'events': eventArr,
      'classes': classesArr,
      'services': servicesArr
    }
    commit('setServices', sortedActivities['services'])
    commit('addOrReplaceSchedules', data.included)
    return data.data
  },
  async fetchStores ({ commit }) {
    const { data } = await getClient().get('/stores?page=1&size=100&include=activities.stores,schedules')
    commit('setStores', data.data)
    commit('setGlobalStoreMessage', data.meta['global-message'])

    const eventArr = []
    const classesArr = []
    const servicesArr = []
    if (typeof data.included !== 'undefined') {
    for (const activity of data.included) {
      if (activity['attributes']['activity-type'] === 'Event') {
        eventArr.push(activity)
      } else if (activity['attributes']['activity-type'] === 'Beauty Class') {
        classesArr.push(activity)
      } else if (activity['attributes']['activity-type'] === 'Service') {
        servicesArr.push(activity)
      }
    }
  }
    var sortedActivities = {
      'events': eventArr,
      'classes': classesArr,
      'services': servicesArr
    }
    commit('setServices', sortedActivities['services'])
    // commit('setEvents', sortedActivities['events'])
    // commit('addOrReplaceBeautyClasses', sortedActivities['classes'])
    // commit('addOrReplaceSchedules', data.included)
    return data.data
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}
